<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-input size="small" v-model.trim="query.account" clearable placeholder="员工手机号" class="handle-input mr10" style="width: 180px"></el-input>
				<el-select size="small" v-model="query.distance" filterable clearable placeholder="请选择距离" class="handle-select mr10">
					<el-option v-for="item in distanceList" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search" @click="handleSearch">搜索</el-button>
			</div>

			<el-table
				:data="tableData"
				border
				class="table"
				ref="multipleTable"
				header-cell-class-name="table-header"
				:header-cell-style="{ 'text-align': 'center' }"
				@selection-change="handleSelectionChange"
				:row-style="{ height: '20px' }"
				:cell-style="{ padding: '6px' }"
				:row-class-name="tableRowClassName"
			>
				<el-table-column prop="agentName" label="代理" align="center"></el-table-column>
				<el-table-column prop="companyName" label="企业" align="center"></el-table-column>
				<el-table-column prop="userPhone" label="用户名" align="center"></el-table-column>
				<el-table-column prop="gap" label="距离" align="center">
					<template #default="scope">
						{{ scope.row.gap.toFixed(0) }}米
					</template>
				</el-table-column>
				<el-table-column prop="lastAddress" label="上次登录地址" align="center">
					<template #default="scope">
						{{ scope.row.lastAddress }}
					</template>
				</el-table-column>

				<el-table-column prop="lastTime" label="上次登录时间" align="center"></el-table-column>
				<el-table-column prop="thisAddress" label="本次登录地址" align="center"></el-table-column>

				<el-table-column prop="thisTime" label="本次登录时间" align="center"></el-table-column>
			</el-table>

			<div class="pagination">
				<el-pagination
					background
					layout="total, sizes, prev, pager, next, jumper"
					:current-page="query.pageIndex"
					:page-sizes="[30, 50, 100, 500]"
					:page-size="query.pageSize"
					:total="pageTotal"
					@size-change="handleSizeChange"
					@current-change="handlePageChange"
				></el-pagination>
			</div>
		</div>
		<div id="container"></div>
	</div>
</template>

<script>
import { addressErrList } from '../api/index.js';
import AMapLoader from '@amap/amap-jsapi-loader';
window._AMapSecurityConfig = {
	securityJsCode: '4cf6ea39a6d386c4f2801cdf2daa4491' //在这里填写你的安全密钥
};
export default {
	name: 'user',
	data() {
		return {
			theme: localStorage.getItem('theme'),
			query: {
				account: '',
				distance: '',
				pageIndex: 1,
				pageSize: 100
			},
			pageTotal: 0,
			tableData: [],
			distanceList: [
				{ value: 1, label: '1千米' },
				{ value: 2, label: '2千米' },
				{ value: 3, label: '3千米' },
				{ value: 4, label: '4千米' },
				{ value: 5, label: '5千米' },
				{ value: 6, label: '6千米' },
				{ value: 7, label: '7千米' },
				{ value: 8, label: '8千米' },
				{ value: 9, label: '9千米' }
			]
		};
	},
	created() {
		const role = localStorage.getItem('ms_role');

		this.getaddressErrList();
	},
	filters: {},
	computed: {},
	methods: {
		tableRowClassName({ rowIndex }) {
			if ((rowIndex + 1) % 2 === 0) {
				return 'oddRow';
			}
			return 'evenRow';
		},
		getAddresss(lng) {
			AMapLoader.load({
				key: '23c769dc056e93763bbef965a621c25d', // 申请好的Web端开发者Key，首次调用 load 时必填
				version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
				plugins: ['AMap.Geocoder'] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
			})
				.then(AMap => {
					AMap.plugin('AMap.Geocoder', () => {
						const geocoder = new AMap.Geocoder();
						geocoder.getAddress(lng.split(','), (status, result) => {
							if (status === 'complete' && result.info === 'OK') {
								return result.regeocode.formattedAddress;
							}
						});
					});
				})
				.catch(e => {
					console.log(e);
				});
		},
		getaddressErrList() {
			let data = {
				userId: localStorage.getItem('user'),
				pageIndex: this.query.pageIndex,
				pageSize: this.query.pageSize,
				distance: this.query.distance
			};
			addressErrList(data).then(res => {
				if (res.code == 200) {
					this.tableData = res.data.list;
					// .forEach((item, index) => {

					// });
					this.pageTotal = res.data.total;
				} else {
					this.$message.error(res.message);
				}
			});
		},

		// 触发搜索按钮
		handleSearch() {
			this.query.pageIndex = 1;
			this.getaddressErrList();
		},
		// 分页导航
		handlePageChange(val) {
			this.query.pageIndex = val;
			this.getaddressErrList();
		},
		handleSizeChange(val) {
			this.query.pageSize = val;
			this.getaddressErrList();
		}
	}
};
</script>

<style scoped>
.handle-box {
	margin-bottom: 20px;
}
.down {
	border: 1px solid #dcdfe6;
	background: #4f7afd;
	color: #ffffff;
	transition: 0.1s;
	font-weight: 500;
	padding: 8px 16px;
	font-size: 12px;
	border-radius: 4px;
	margin-left: 10px;
	
	display: inline-block;
}

.handle-select {
	width: 120px;
}

.handle-input {
	width: 300px;
	display: inline-block;
}
.table {
	width: 100%;
	font-size: 12px;
}
.red {
	color: #f56c6c;
}
.mr10 {
	margin-right: 10px;
}
.grid-con-icon {
	font-size: 50px;
	width: 100px;
	height: 100px;
	text-align: center;
	line-height: 100px;
	color: #fff;
}
.grid-cont-right {
	flex: 1;
	text-align: center;
	font-size: 20px;
	font-weight: bold;
	color: #999;
}
.grid-content {
	display: flex;
	align-items: center;
	height: 100px;
}
</style>
